<template>
  <div class="container mt-4  px-5">
    <v-alert
        v-show="comp_OpenShootingBookData_Club_DefaultClub"
        color="red"
        type="error">
      Du hast keinen Standardverein festgelegt, bitte stelle auf deinem <a href="/user/profil">Profil</a>
      zuerst deinen Verein ein, bzw. lasse ihn vom Verein bestätigen!
    </v-alert>
    <v-card>
      <v-card-title>
        Profil
      </v-card-title>
      <v-card-text>
        <v-form ref="form" v-on:submit.prevent>
          <v-row>
            <v-col>
              <v-text-field
                  label="Name"
                  v-model="userprofil.name"
                  placeholder="Name"
                  outlined
                  :rules="[rules.required]"
                  :error-messages="errorMessages"
              ></v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                  label="Vorname"
                  v-model="userprofil.vorname"
                  placeholder="Vorname"
                  outlined
                  :rules="[rules.required]"
                  :error-messages="errorMessages"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                  label="Email"
                  v-model="userprofil.email"
                  placeholder="Email"
                  outlined
                  :rules="[rules.required, rules.email]"
                  :error-messages="errorMessages"
              ></v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                  label="Mitgliedsnummer"
                  v-model="userprofil.Mitgliedsnummer"
                  placeholder="Mitgliedsnummer"
                  outlined
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col
                cols="6"
                sm="6">
              <v-text-field
                  label="IPSC Nummer"
                  v-model="userprofil.ipsc_number"
                  placeholder="IPSC Nummer"
                  outlined
              ></v-text-field>
            </v-col>

          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                  label="NWR Personen ID"
                  v-model="userprofil.NWR_PID"
                  placeholder="NWR Personen ID"
                  outlined
              ></v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                  label="NWR Erlaubnis ID"
                  v-model="userprofil.NWR_EID"
                  placeholder="NWR Erlaubnis ID"
                  outlined
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                  label="Telefon"
                  v-model="userprofil.telefon"
                  placeholder="Telefon"
                  outlined
              ></v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                  label="Strasse"
                  v-model="userprofil.strasse"
                  placeholder="Strasse"
                  outlined
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                  label="Stadt"
                  v-model="userprofil.ort"
                  placeholder="Stadt"
                  outlined
              ></v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                  label="PLZ"
                  v-model="userprofil.PLZ"
                  placeholder="PLZ"
                  outlined
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-select
                  v-model="userprofil.defaultVerein"
                  :items="comp_allClubs"
                  item-text="Hauptverein"
                  item-value="id"
                  label="Hauptverein"
                  value=""
                  no-data-text="Du kannst noch keinen Verein auswählen"
                  return-object
                  outlined
                  class="mx-1"
                  :rules="[rules.requiredObject]"
                  :error-messages="errorMessages"
              >
                <template slot="selection" slot-scope="data">
                  <!-- HTML that describe how select should render selected items -->
                  {{ data.item.Vereinsnummer }} {{ data.item.name }}
                </template>
                <template slot="item" slot-scope="data">
                  <!-- HTML that describe how select should render items when the select is open -->
                  {{ data.item.Vereinsnummer }} {{ data.item.name }}
                </template>

              </v-select>
            </v-col>
          </v-row>
<!--          <v-row>-->
<!--            <v-col>-->
<!--              <v-text-field-->
<!--                  label="RFID-Token"-->
<!--                  v-model="userprofil.APP_Token"-->
<!--                  placeholder="RFID-Token"-->
<!--                  outlined-->
<!--              ></v-text-field>-->
<!--            </v-col>-->
<!--            <v-col></v-col>-->
<!--          </v-row>-->
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-row>
          <v-col>
            <v-btn
                color="success"
                @click="save">
              Aktualisieren
            </v-btn>
          </v-col>
          <v-col>
            <!--              <v-btn-->
            <!--                  outlined-->
            <!--                  color="red darken-2">-->
            <!--                Profil löschen-->
            <!--              </v-btn>-->
          </v-col>
        </v-row>

      </v-card-actions>
    </v-card>
  </div>

</template>

<script>
export default {
  name: "profil",
  data: () => ({
    userprofil: {
      vorname: '',
      name: '',
      mobil: '',
      strasse: '',
      telefon: '',
      PLZ: '',
      NWR_EID: '',
      NWR_PID: '',
      Mitgliedsnummer: '',
      ipsc_number: '',
      APP_Token: '',
      email: '',
      defaultVerein: '',
      ort: '',
    },
    errorMessages: '',
    rules: {
      required: value => !!value || 'Pflichtfeld.',
      requiredObject: value => !!value || 'Pflichtfeld.',
      counter: value => value.length <= 200 || 'Max 200 Zeichen',
      email: value => {
        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        return pattern.test(value) || 'Ungültige E-Mail.'
      },
    },
  }),
  computed: {
    comp_allClubs() {
      return this.$store.state.UserApp.Clubs.bestVereine;
    },
    comp_OpenShootingBookData_Club_DefaultClub() {
      const state = this.$store.state.UserApp.OpenShootingBookData;
      var data = false;
      if (state != null && Object.keys(state).length > 0) {
        data = state.Club.Vereinsnummer === '99999';
      }
      return data;
    }
  },
  mounted() {
    this.getUserProfil();
  },
  methods: {
    getUserProfil() {
      this.userprofil = this.$store.state.UserAuth.user;
    },
    async save() {
      if (!this.$refs.form.validate()) {
        this.errorMessages = 'Bitte alle erforderlichen Felder korrekt ausfüllen.';
        return;
      }
      if (!this.userprofil.defaultVerein) {
        this.errorMessages = 'Kein Hauptverein ausgewählt.';
        return;
      }

      var Data = {
        inputName: this.userprofil.name,
        inputVorname: this.userprofil.vorname,
        inputNWRPID: this.userprofil.NWR_PID,
        inputNWREID: this.userprofil.NWR_EID,
        inputTelefon: this.userprofil.telefon,
        inputStrasse: this.userprofil.strasse,
        inputMitg: this.userprofil.Mitgliedsnummer,
        ipsc_number: this.userprofil.ipsc_number,
        inputOrt: this.userprofil.ort,
        inputPLZ: this.userprofil.PLZ,
        inputDefVerein: this.userprofil.defaultVerein,
        inputEmail4: this.userprofil.email,
        inputMobil: this.userprofil.mobil,
      };
      await this.$store.dispatch('UserAuth/setUserProfilData', Data)

      if (this.$refs.form) {
        // Validierungsstatus explizit zurücksetzen
        this.$refs.form.resetValidation();
        this.errorMessages = '';
      }
    },

  },
}
</script>

<style scoped>

</style>
